<template>
    <div>
        <div class="row-fluid head title">
            <h1>Teams</h1>
        </div>

        <div class="row-fluid">
            <div class="span12">
                <div v-if="error" class="alert">{{error}}</div>
                <loading-message v-else-if="!accountTeams" message="Fetching teams…"/>
                <template v-else>
                    <table class="table table-condensed table-bordered table-hover">
                        <thead>
                        <tr>
                            <td>Team</td>
                            <td>Description</td>
                            <td>Users</td>
                        </tr>
                        </thead>
                        <tbody>
                        <team-row v-for="t in accountTeams" :team="t" :key="t.id" :can-edit="canEdit"/>
                        <tr v-if="!accountTeams.length">
                            <td colspan="2">No teams found</td>
                        </tr>
                        </tbody>
                    </table>
                    <div style="margin-bottom: 20px">
                        <div style="margin-bottom: 10px">Group users into teams to control access to dashboards and other functionality</div>
                        <a v-if="canEdit" class="btn btn-primary" @click="add">Add Team</a>
                    </div>
                </template>
            </div>
        </div>

        <edit-team-dialog v-if="showAdd && newTeam" :team="newTeam" v-model="showAdd"/>
    </div>
</template>

<script>
    import LoadingMessage from "@/components/LoadingMessage";
    import TeamRow from "./TeamRow"
    import EditTeamDialog from "./EditTeamDialog";
    import {isAccountAdmin} from "@/app/Permissions";
    import {mapActions, mapState} from "vuex";
    import {setTitle} from "@/app/Beef";

    export default {
        name: "SetupTeams",

        components: { TeamRow, LoadingMessage, EditTeamDialog },

        data() {
            return {
                error: null,
                showAdd: false,
                newTeam: null
            }
        },

        computed: {
            ...mapState(['accountTeams']),

            canEdit() { return isAccountAdmin() }
        },

        async mounted() {
            setTitle("Teams");
            await this.refreshAccountUsers();
            await this.refreshAccountTeams()
        },

        methods: {
            ...mapActions('accountUsers', ['refreshAccountUsers']),
            ...mapActions(['refreshAccountTeams']),

            add() {
                this.newTeam = { }
                this.showAdd = true
            }
        }
    }

</script>

<style scoped>
</style>